<template>
  <section id="planning">
    <pibot-section-heading>
      <slot>IRM Planning</slot>
    </pibot-section-heading>

    <v-data-table
      dense
      :loading="loading"
      loading-text="Loading planning... Please wait"
      :headers="headers"
      :items="items"
      sort-by="year"
    >
    </v-data-table>
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'

export default {
  name: 'pibot-asset-planning',
  components: {
    'pibot-section-heading': SectionHeading
  },
  data () {
    return {
      loading: false,
      headers: [
        {
          text: 'Year',
          value: 'year',
          align: 'start',
          sortable: true
        },
        {
          text: 'Type',
          value: 'type',
          align: 'start',
          sortable: true
        },
        {
          text: 'Description',
          value: 'description',
          align: 'start',
          sortable: true
        },
        {
          text: 'Estimated Costs [€]',
          value: 'costs',
          align: 'start',
          sortable: true
        }
      ],
      items: [
        {
          year: 2020,
          type: 'Scheduled Inspection',
          documents: [
            {
              title: 'Report',
              path: 'about:blank'
            },
            {
              title: 'Pipe Tally',
              path: 'about:blank'
            }
          ],
          description: 'Cathodic protection check-up'
        },
        {
          year: 2020,
          type: 'Recommended Inspection',
          documents: [
            {
              title: 'Report',
              path: 'about:blank'
            },
            {
              title: 'Pipe Tally',
              path: 'about:blank'
            }
          ],
          description: 'In-line Inspection (UT)'
        }
      ]
    }
  }
}
</script>
